import { Pipe, PipeTransform } from '@angular/core';
import {CheckThumbnailService} from '../providers/check-thumbnail.service';

@Pipe({
  name: 'thumbnail'
})
export class ThumbnailPipe implements PipeTransform {

  constructor( private checkUrl: CheckThumbnailService) {}

  transform(name: any): any {
    name = name.replace(/\s+/g, '');
    const fields = name.split('-');
    return null;
  }

}
