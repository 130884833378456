import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-nav',
  templateUrl: './horizontal-nav.component.html',
  styleUrls: ['./horizontal-nav.component.css']
})
export class HorizontalNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
