import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-spell-card',
  templateUrl: './spell-card.component.html',
  styleUrls: ['./spell-card.component.css']
})
export class SpellCardComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() spellName: String;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  spellDetails( item: any) {
    this.router.navigate(['/mageSpell', item.id]);
  }

}
