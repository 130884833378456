import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-monster-card',
  templateUrl: './monster-card.component.html',
  styleUrls: ['./monster-card.component.css']
})
export class MonsterCardComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() name: string;
  @Input() terrain: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
