import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MonstersService {

  constructor(private http: HttpClient) {
  }

  getQuery( query: string) {
    //const IP = window.location.hostname;
    //const URL = `http://${IP}:82/api/${query}`;
    const URL = `https://mgdnd.ddns.net/api/${query}`;

    return this.http.get(URL);
  }

  getAllMonsters() {
    return this.getQuery('monsters')
      .pipe( map ( data => data ));
  }

  getMonster( id: number) {
    return this.getQuery(`monsters/${id}`)
      .pipe( map ( data => data ));
  }

  getMonsterByName( name: string) {
    return this.getQuery(`monsters/${name}`)
      .pipe( map ( data => data ));
  }
}
