import {Routes, RouterModule} from '@angular/router';

import {SpellsComponent} from './components/spells/spells.component';
import {HomeComponent} from './components/home/home.component';
import {PriestSpellsComponent} from './components/priest-spells/priest-spells.component';
import {MageGeneratorComponent} from './components/mage-generator/mage-generator.component';
import {DetailsComponent} from './components/spells/details/details.component';
import {PriestDetailsComponent} from './components/priest-spells/priest-details/priest-details.component';
import {MonstersComponent} from './components/monsters/monsters.component';
import {MonsterDetailsComponent} from './components/monsters/monster-details/monster-details.component';
import {SheetComponent} from './components/mage-generator/sheet/sheet.component';
import {SpellBookComponent} from './components/spell-book/spell-book.component';
import {SpellBookSheetComponent} from './components/spell-book/spell-book-sheet/spell-book-sheet.component';
import {PriestGeneratorComponent} from './components/priest-generator/priest-generator.component';


const APP_ROUTES: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'mageSpells', component: SpellsComponent},
  {path: 'mageSpells/:id', component: DetailsComponent},
  {path: 'priestSpells', component: PriestSpellsComponent},
  {path: 'priestSpells/:id', component: PriestDetailsComponent},
  {path: 'mageGenerator', component: MageGeneratorComponent},
  {path: 'priestGenerator', component: PriestGeneratorComponent},
  {path: 'spellBook', component: SpellBookComponent},
  {path: 'monsters', component: MonstersComponent},
  {path: 'monsters/:id', component: MonsterDetailsComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'home'}
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
