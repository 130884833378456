import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thumbnails'
})
export class ThumbnailsPipe implements PipeTransform {

  transform(name: any): any {
    const IP = window.location.hostname;
    return `http://${IP}:82/image/thumbnails/${name}.png`;
  }

}
