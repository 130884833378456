import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MonstersService} from '../../../providers/monsters.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-monster-details',
  templateUrl: './monster-details.component.html',
  styleUrls: ['./monster-details.component.css']
})
export class MonsterDetailsComponent implements OnInit {

  monster: any = {};

  constructor( private activatedRoute: ActivatedRoute, private monsterService: MonstersService, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe( params => {
      this.monsterService.getMonster(params.id)
        .subscribe((data: any) => {
          this.monster = data;
        });
    });
  }

  ngOnInit() {
  }

}
