import {Component, OnInit} from '@angular/core';
import {GeneratorService} from '../../providers/generator.service';

import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-spell-book',
  templateUrl: './spell-book.component.html',
  styleUrls: ['./spell-book.component.css']
})
export class SpellBookComponent implements OnInit {

  spells = [];

  constructor(private generator: GeneratorService) {
  }

  generarLibro() {
    const result = document.getElementById('result');
    result.style.display = 'block';

    const maxSpell = (document.getElementById('max-spell') as HTMLOptionElement).value;
    const spells = (document.getElementById('spells') as HTMLOptionElement).value;
    const school = (document.getElementById('school') as HTMLOptionElement).value;

    this.generator.getSpells(maxSpell, spells, school)
      .subscribe((data: any) => {
        this.spells = data;
      });
    document.getElementById('exportButton').style.display = 'inline-block';
  }

  ngOnInit() {
  }

  async export() {
    document.getElementById('myModal').style.display = 'block';

    const imgWidth = 210;
    const pageHeight = 295;
    let position = 0;
    let heightLeft = pageHeight;
    const doc = new jsPDF('p', 'mm');

    const longitud = this.spells.length;

    await html2canvas(document.getElementById('header'), {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function (canvas) {
      const imgHeight = canvas.height * imgWidth / canvas.width;
      if (heightLeft >= imgHeight + 5) {
        doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
        position = imgHeight;
        heightLeft -= imgHeight;
      } else {
        heightLeft = pageHeight;
        doc.addPage();
        position = 0;
        doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
        position += imgHeight;
        heightLeft -= imgHeight;
      }
    });

    for (let i = 0; i < longitud; i++) {
      await html2canvas(document.getElementById('sheet' + i), {
        // Opciones
        allowTaint: true,
        useCORS: false,
        // Calidad del PDF
        scale: 1
      }).then(function (canvas) {
        const imgHeight = canvas.height * imgWidth / canvas.width;

        if (heightLeft >= imgHeight + 5) {
          doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
          position += imgHeight;
          heightLeft -= imgHeight;
        } else {
          heightLeft = pageHeight;
          doc.addPage();
          position = 0;
          doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
          position += imgHeight;
          heightLeft -= imgHeight;
        }

        if (i === longitud - 1) {
          doc.save('spellbook.pdf');
          document.getElementById('myModal').style.display = 'none';
        }
      });
    }
  }

}
