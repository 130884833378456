import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castTime'
})
export class CastTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === null) {
      return 0;
    } else {
      return value.toString().substr(0, 1);
    }
  }

}
