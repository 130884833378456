import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneratorService {

  constructor(private http: HttpClient) { }

  getQuery( query: string) {
    //const IP = window.location.hostname;
    //const URL = `http://192.168.10.110:82/api/${query}`;
    const URL = `https://mgdnd.ddns.net/api/${query}`;
    return this.http.get(URL);
  }

  getSpells(lvl, numSpells, school) {
    return this.getQuery(`spellBook/${lvl}/${numSpells}/${school}`)
      .pipe( map ( data => data ));
  }

  getAttributes(lvl, race, school) {
    return this.getQuery(`mageGenerator/${lvl}/${race}/${school}`)
      .pipe( map ( data => data ));
  }

  getPriest(lvl, race, subclass) {
    return this.getQuery(`priestGenerator/${lvl}/${race}/${subclass}`)
      .pipe( map ( data => data ));
  }
}
