import { Component, OnInit } from '@angular/core';
import {GeneratorService} from '../../../providers/generator.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.css']
})
export class SheetComponent implements OnInit {
  attributes = {
    'str': [],
    'dex': [],
    'con': [],
    'int': [],
    'wis': [],
    'cha': []
  };

  race = {
    'race': '',
    'extra_data': '',
    'infravision': '',
  };

  spells = [];

  savings = {
    'id': '',
    'class_id': '',
    'lvl': '',
    'paralization': '',
    'wand': '',
    'petrification': '',
    'breath_weapon': '',
    'spells': ''
  };

  lvl: number;

  class: string;

  constructor(private generator: GeneratorService) {

    this.generator.getAttributes(5, 6, 1)
      .subscribe((data: any) => {
        this.attributes.str = data.atributos.str[0];
        this.attributes.dex = data.atributos.dex[0];
        this.attributes.con = data.atributos.con[0];
        this.attributes.int = data.atributos.int[0];
        this.attributes.wis = data.atributos.wis[0];
        this.attributes.cha = data.atributos.cha[0];

        this.race.race = data.raza[0].race;
        this.race.extra_data = data.raza[0].extra_data;
        this.race.infravision = data.raza[0].infravision;

        this.spells = data.hechizos;

        this.savings.id = data.salvacion[0].id;
        this.savings.class_id = data.salvacion[0].class_id;
        this.savings.lvl = data.salvacion[0].lvl;

        if (data.escuela !== 0) {
          this.class = 'Mage / ' + data.escuela[0].text;
        } else {
          this.class = 'Mage';
        }


        this.savings.petrification = data.salvacion[0].petrification;
        this.savings.breath_weapon = data.salvacion[0].breath_weapon;

        // elfos, gnomos, semielfos y humanos => 2-3-4-6
        if (data.raza[0].id === 2 || data.raza[0].id === 3 || data.raza[0].id === 4 || data.raza[0].id === 6) {
          this.savings.paralization = (data.salvacion[0].paralization - data.atributos.con[0].poison_save).toString();
        } else {
          this.savings.paralization = data.salvacion[0].paralization + '/';
          this.savings.paralization += (data.salvacion[0].paralization + data.bonus_salvacion[0].saving_bonus).toString();
        }

        if (data.raza[0].id === 5) {
          this.savings.wand = (data.salvacion[0].wand + data.bonus_salvacion[0].saving_bonus).toString();
        } else {
          this.savings.wand = data.salvacion[0].wand;
        }

        if (data.raza[0].id === 5) {
          this.savings.spells = (data.salvacion[0].spells + data.bonus_salvacion[0].saving_bonus).toString();
        } else {
          this.savings.spells = data.salvacion[0].spells;
        }

        // this.lvl = lvl;
        this.lvl = 5;
      });
  }

  ngOnInit() {
  }

  download() {
    const imgWidth = 210;
    const position = 0;
    const doc = new jsPDF('p', 'mm');
    const header = document.getElementById('sheet');

    html2canvas(header, {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function (canvas) {
      const img = canvas.toDataURL('image/png');
      const imgHeight = canvas.height * imgWidth / canvas.width;
      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      doc.save('spellbook.pdf');
    });
  }
}
