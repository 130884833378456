import {Component, OnInit} from '@angular/core';
import {MonstersService} from '../../providers/monsters.service';

@Component({
  selector: 'app-monsters',
  templateUrl: './monsters.component.html',
  styleUrls: ['./monsters.component.css']
})
export class MonstersComponent implements OnInit {

  filtros = {
    'type': [],
    'terrain': '',
    'name': '',
  };

  allMonsters: any[] = [];
  monsters: any[] = [];

  types: string[] = [];

  public terrain = '';
  public name = '';

  constructor(private monstersService: MonstersService) {
    this.monstersService.getAllMonsters()
      .subscribe((data: any) => {
        this.allMonsters = data;
      });

    this.monsters = this.getMonsters();
  }

  addType(typeName: string) {
    if (this.types.includes(typeName)) {
      const INDEX = this.types.indexOf(typeName);
      if (INDEX > -1) {
        this.types.splice(INDEX, 1);
      }
    } else {
      this.types.push(typeName);
    }

    this.filtros.type = this.types;
    this.monsters = [];
    this.monsters = this.getMonsters();
  }

  getMonsters() {
    const monsterFiltered: any[] = [];
    if (this.filtros.type.length > 0) {
      for (const monstruo of this.allMonsters) {
        for (const tipo of this.filtros.type) {
          if (monstruo.type.search(tipo) !== -1) {
            monsterFiltered.push(monstruo);
          }
        }
      }
    }
    return monsterFiltered;
  }

  addTerrain(event: any) {
    this.terrain = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
  }

  addName(event: any) { // without type info
    this.name = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
  }

  ngOnInit() {
  }

}
