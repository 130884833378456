import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDesc'
})
export class ShortDescPipe implements PipeTransform {

  transform(text: any, length: any): any {
    const palabras = text.split(' ', length);
    let shortDesc = '';

    for ( const palabra of palabras) {
      shortDesc += palabra + ' ';
    }

    return shortDesc + '...';
  }

}
