import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_ROUTING } from './app.routes';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { TopbarComponent } from './components/header/topbar/topbar.component';
import { HorizontalNavComponent } from './components/header/horizontal-nav/horizontal-nav.component';
import { SpellsComponent } from './components/spells/spells.component';
import { HomeComponent } from './components/home/home.component';
import { SidebarComponent } from './components/spells/sidebar/sidebar/sidebar.component';

// Firebase
import { SpellCardComponent } from './components/spells/card/spell-card/spell-card.component';
import { SpellService } from './providers/spell.service';
import { ShortDescPipe } from './pipes/short-desc.pipe';
import { PriestSpellsComponent } from './components/priest-spells/priest-spells.component';
import { PriestSpellCardComponent } from './components/priest-spells/card/priest-spell-card/priest-spell-card.component';
import { EighteenPipe } from './pipes/eighteen.pipe';
import { MageGeneratorComponent } from './components/mage-generator/mage-generator.component';
import { DetailsComponent } from './components/spells/details/details.component';
import { PriestDetailsComponent } from './components/priest-spells/priest-details/priest-details.component';
import { ThumbnailsPipe } from './pipes/thumbnails.pipe';
import {GeneratorService} from './providers/generator.service';
import {MonstersService} from './providers/monsters.service';
import { MonstersComponent } from './components/monsters/monsters.component';
import { MonsterCardComponent } from './components/monsters/monster-card/monster-card.component';
import { MonsterSidebarComponent } from './components/monsters/monster-sidebar/monster-sidebar.component';
import { MonsterDetailsComponent } from './components/monsters/monster-details/monster-details.component';
import { ThumbnailPipe } from './pipes/thumbnail.pipe';
import {CheckThumbnailService} from './providers/check-thumbnail.service';
import { SheetComponent } from './components/mage-generator/sheet/sheet.component';
import { SpellBookComponent } from './components/spell-book/spell-book.component';
import { SpellBookSheetComponent } from './components/spell-book/spell-book-sheet/spell-book-sheet.component';
import { CastTimePipe } from './pipes/cast-time.pipe';
import { MonsterFilterPipe } from './pipes/monster-filter.pipe';
import { SpellFilterPipe } from './pipes/spell-filter.pipe';
import { PriestGeneratorComponent } from './components/priest-generator/priest-generator.component';
import {FormsModule} from '@angular/forms';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TopbarComponent,
    HorizontalNavComponent,
    SpellsComponent,
    HomeComponent,
    SidebarComponent,
    SpellCardComponent,
    ShortDescPipe,
    PriestSpellsComponent,
    PriestSpellCardComponent,
    EighteenPipe,
    MageGeneratorComponent,
    DetailsComponent,
    PriestDetailsComponent,
    ThumbnailsPipe,
    MonstersComponent,
    MonsterCardComponent,
    MonsterSidebarComponent,
    MonsterDetailsComponent,
    ThumbnailPipe,
    SheetComponent,
    SpellBookComponent,
    SpellBookSheetComponent,
    CastTimePipe,
    MonsterFilterPipe,
    SpellFilterPipe,
    PriestGeneratorComponent,
    SafeHTMLPipe,
    SafeHTMLPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    APP_ROUTING,
    FormsModule
  ],
  providers: [
    SpellService,
    GeneratorService,
    MonstersService,
    CheckThumbnailService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
