import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eighteen'
})
export class EighteenPipe implements PipeTransform {

  transform(score: any, eighteen: any): any {
    if (score === 18) {
      return score + '/' + eighteen;
    } else {
      return score;
    }
  }

}
