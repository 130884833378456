import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spellFilter'
})
export class SpellFilterPipe implements PipeTransform {

  transform(items: any, filter: any): any {
    const filterKeys = ['name'];
    return items.filter(item =>
      filterKeys.reduce((memo, keyName) =>
        (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));

    // return items;
  }

}
