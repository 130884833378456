import { Component, OnInit } from '@angular/core';
import { SpellService } from '../../../providers/spell.service';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  spell: any = {
    'name': String,
    'type': String,
    'lvl': String,
    'types': String,
    'rng': String,
    'dur': String,
    'compo': String,
    'castime': String,
    'aoe': String,
    'saving': String,
    'body': String,
  };
  body: any;

  constructor( private activatedRoute: ActivatedRoute, private spellService: SpellService, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe( params => {
      this.spellService.getMageSpell(params.id)
        .subscribe((data: any) => {
          this.spell = data;
        });
    });
  }

  ngOnInit() {
  }
}
