import {Component, OnInit} from '@angular/core';
import {SpellService} from '../../providers/spell.service';

@Component({
  selector: 'app-spells',
  templateUrl: './spells.component.html',
  styleUrls: ['./spells.component.css']
})

export class SpellsComponent implements OnInit {

  allSpells: any[] = [];
  spells: any[] = [];
  levels: string[] = [];
  mensajeError: any = {};

  schools: string[] = [];

  filtros = {
    'levels': [],
    'schools': [],
    'spheres': [],
    'type': []
  };

  public spellName = '';

  constructor(private spellService: SpellService) {
    this.spellService.getAllMageSpells()
      .subscribe((data: any) => {
        this.allSpells = data;
      });
    this.spells = this.getSpells();
  }

  ngOnInit() {
  }

  getSpells() {
    const spellFiltered: any[] = [];

    if (this.filtros.levels.length > 0) {
      if (this.filtros.schools.length > 0) {
        // filtrado por nivel y escuela
        for (const hechizo of this.allSpells) {
          for (const nivel of this.filtros.levels) {
            for (const school of this.filtros.schools) {
              if (hechizo.lvl === nivel && hechizo.schools.search(school) !== -1) {
                spellFiltered.push(hechizo);
              }
            }
          }
        }
      } else {
        // filtrado solo por nivel
        for (const hechizo of this.allSpells) {
          for (const nivel of this.filtros.levels) {
            if (hechizo.lvl === nivel) {
              spellFiltered.push(hechizo);
            }
          }
        }
      }
    } else if (this.filtros.schools.length > 0) {
      // filtrado solo por escuela
      for (const hechizo of this.allSpells) {
        for (const school of this.filtros.schools) {
          if (hechizo.schools.search(school) !== -1) {
            spellFiltered.push(hechizo);
          }
        }
      }
    }

    return spellFiltered;
  }

  addFilter(key, event: any) {
    if (key === 'name') {
      this.spellName = event.target.value;
    } else if (key === 'lvl') {
      if (this.levels.includes(event)) {
        const INDEX = this.levels.indexOf(event);
        if (INDEX > -1) {
          this.levels.splice(INDEX, 1);
        }
      } else {
        this.levels.push(event);
      }
    } else if (key === 'school') {
      if (this.schools.includes(event)) {
        const INDEX = this.schools.indexOf(event);
        if (INDEX > -1) {
          this.schools.splice(INDEX, 1);
        }
      } else {
        this.schools.push(event);
      }
    }

    this.spellerdos();
  }

  spellerdos() {
    let spellFiltered: any[] = [];
    let filterKeys: string[];

    if (this.spellName.length > 0) {
      filterKeys = ['name'];
      spellFiltered = this.allSpells.filter(item =>
        filterKeys.reduce((memo, keyName) =>
          (memo && new RegExp(this.spellName, 'gi').test(item[keyName])) || this.spellName === '', true));

      if (this.levels.length > 0) {
        filterKeys = ['lvl'];

        const spellFilteredLvl = spellFiltered.slice();
        spellFiltered.splice(0, spellFiltered.length);

        for (let i = 0; i < this.levels.length; i++) {
          const arrayTmp = spellFilteredLvl.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.levels[i], 'gi').test(item[keyName])) || this.levels[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }

      if (this.schools.length > 0) {
        filterKeys = ['schools'];

        const spellFilteredLvl = spellFiltered.slice();
        spellFiltered.splice(0, spellFiltered.length);

        for (let i = 0; i < this.schools.length; i++) {
          const arrayTmp = spellFilteredLvl.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.schools[i], 'gi').test(item[keyName])) || this.schools[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }
    } else {
      if (this.levels.length > 0) {
        let spellFilteredLvl: string[];
        if (spellFiltered.length === 0) {
          spellFilteredLvl = this.allSpells.slice();
        } else {
          spellFilteredLvl = spellFiltered.slice();
          spellFiltered.splice(0, spellFiltered.length);
        }
        filterKeys = ['lvl'];

        for (let i = 0; i < this.levels.length; i++) {
          const arrayTmp = spellFilteredLvl.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.levels[i], 'gi').test(item[keyName])) || this.levels[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }

      if (this.schools.length > 0) {
        let spellFilteredSchools: string[];
        if (spellFiltered.length === 0) {
          spellFilteredSchools = this.allSpells.slice();
        } else {
          spellFilteredSchools = spellFiltered.slice();
          spellFiltered.splice(0, spellFiltered.length);
        }
        filterKeys = ['schools'];

        for (let i = 0; i < this.schools.length; i++) {
          const arrayTmp = spellFilteredSchools.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.schools[i], 'gi').test(item[keyName])) || this.schools[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }
    }

    if (this.spellName.length === 0 && this.levels.length === 0 && this.schools.length === 0) {
      this.spells = [];
    } else {
      this.spells = spellFiltered;
    }
  }
}
