import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'monsterFilter'
})
export class MonsterFilterPipe implements PipeTransform {

  transform(items: any, filter: any, isAnd: boolean): any {
    const monsterFiltered = new Array();

    for (const monstruo of items) {
      if (monstruo.name.search(filter.name) !== -1 && monstruo.terrain.search(filter.terrain) !== -1) {
        monsterFiltered.push(monstruo);
      }
    }

    return monsterFiltered;
  }
}
