import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckThumbnailService {

  constructor(private http: HttpClient) { }

  getQuery( query: string) {
    const IP = window.location.hostname;
    const URL = `http://${IP}:82/api/${query}`;

    return this.http.get(URL);
  }

  getAttributes() {
    return this.getQuery('mageGenerator')
      .pipe( map ( data => data ));
  }
}
