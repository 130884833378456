import {Component, OnInit} from '@angular/core';
import {GeneratorService} from '../../providers/generator.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-mage-generator',
  templateUrl: './mage-generator.component.html',
  styleUrls: ['./mage-generator.component.css']
})
export class MageGeneratorComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  // 6 = Humano
  selectedRace = 6;

  // 0 = sin subraza
  selectedSubrace = 0;

  attributes = {
    'str': [],
    'dex': [],
    'con': [],
    'int': [],
    'wis': [],
    'cha': []
  };

  race = {
    'race': '',
    'extra_data': '',
    'infravision': '',
    'advantages': '',
    'disadvantages': '',
  };

  spells = [];

  savings = {
    'id': '',
    'class_id': '',
    'lvl': '',
    'paralization': '',
    'wand': '',
    'petrification': '',
    'breath_weapon': '',
    'spells': ''
  };

  lvl: string;

  class: string;

  hitPoints: string;

  constructor(private generator: GeneratorService) {
  }

  onChange(value) {
    this.selectedSubrace = 0;
  }

  generar() {
    document.getElementById('generatingModal').style.display = 'block';

    let raza = 0;
    const lvl = (document.getElementById('lvl') as HTMLOptionElement).value;
    const school = (document.getElementById('school') as HTMLOptionElement).value;

    if (this.selectedSubrace === 0) {
      raza = this.selectedRace;
    } else {
      raza = this.selectedSubrace;
    }

    this.generator.getAttributes(lvl, raza, school)
      .subscribe((data: any) => {
        this.attributes.str = data.atributos.str[0];
        this.attributes.dex = data.atributos.dex[0];
        this.attributes.con = data.atributos.con[0];
        this.attributes.int = data.atributos.int[0];
        this.attributes.wis = data.atributos.wis[0];
        this.attributes.cha = data.atributos.cha[0];

        this.race.race = data.raza[0].race;
        this.race.extra_data = data.raza[0].extra_data;
        this.race.infravision = data.raza[0].infravision;
        this.race.advantages = data.raza[0].advantages;
        this.race.disadvantages = data.raza[0].disadvantages;

        if (data.escuela !== 0) {
          this.class = 'Mage / ' + data.escuela[0].text;
        } else {
          this.class = 'Mage';
        }

        this.lvl = lvl;

        this.spells = data.hechizos;

        this.hitPoints = data.vida;

        this.savings.id = data.salvacion[0].id;
        this.savings.class_id = data.salvacion[0].class_id;
        this.savings.lvl = data.salvacion[0].lvl;


        this.savings.petrification = data.salvacion[0].petrification;
        this.savings.breath_weapon = data.salvacion[0].breath_weapon;

        // elfos, gnomos, semielfos y humanos => 2-3-4-6
        if (data.raza[0].id === 5) {
          this.savings.paralization = data.salvacion[0].paralization + '/';
          this.savings.paralization += (data.salvacion[0].paralization + data.bonus_salvacion[0].saving_bonus).toString();
          this.savings.wand = (data.salvacion[0].wand + data.bonus_salvacion[0].saving_bonus).toString();
          this.savings.spells = (data.salvacion[0].spells + data.bonus_salvacion[0].saving_bonus).toString();
        } else {
          this.savings.wand = data.salvacion[0].wand;
          this.savings.spells = data.salvacion[0].spells;
          this.savings.paralization = (data.salvacion[0].paralization - data.atributos.con[0].poison_save).toString();
        }

        this.dataLoaded = Promise.resolve(true);

        document.getElementById('generatingModal').style.display = 'none';
        document.getElementById('exportButton').style.display = 'inline-block';
      });
  }

  ngOnInit() {
  }

  async export() {
    document.getElementById('myModal').style.display = 'block';

    const imgWidth = 210;
    const pageHeight = 295;
    let position = 0;
    let heightLeft = pageHeight;
    const doc = new jsPDF('p', 'mm');

    const longitud = this.spells.length;

    await html2canvas(document.getElementById('hoja1'), {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function (canvas) {
      doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, canvas.height * imgWidth / canvas.width);
    });

    if (this.selectedSubrace !== 0) {
      await html2canvas(document.getElementById('racial-skills'), {
        // Opciones
        allowTaint: true,
        useCORS: false,
        // Calidad del PDF
        scale: 1
      }).then(function (canvas) {
        doc.addPage();
        doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, canvas.height * imgWidth / canvas.width);
      });
    }

    await html2canvas(document.getElementById('hoja2'), {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function (canvas) {
      doc.addPage();
      doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, canvas.height * imgWidth / canvas.width);
    });

    await html2canvas(document.getElementById('header'), {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function (canvas) {
      const imgHeight = canvas.height * imgWidth / canvas.width;
      heightLeft = pageHeight;
      doc.addPage();
      position = 0;
      doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
      position += imgHeight;
      heightLeft -= imgHeight;
      // }
    });

    for (let i = 0; i < longitud; i++) {
      // const sheet = document.getElementById('sheet' + i);

      await html2canvas(document.getElementById('sheet' + i), {
        // Opciones
        allowTaint: true,
        useCORS: false,
        // Calidad del PDF
        scale: 1
      }).then(function (canvas) {
        const imgHeight = canvas.height * imgWidth / canvas.width;
        if (heightLeft >= imgHeight + 5) {
          doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, canvas.height * imgWidth / canvas.width);
          position += imgHeight;
          heightLeft -= imgHeight;
        } else {
          heightLeft = pageHeight;
          doc.addPage();
          position = 0;
          doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, canvas.height * imgWidth / canvas.width);
          position += imgHeight;
          heightLeft -= imgHeight;
        }

        if (i === longitud - 1) {
          doc.save('character_sheet.pdf');

          document.getElementById('myModal').style.display = 'none';
        }
      });
    }
  }

}
