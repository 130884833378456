import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-priest-spell-card',
  templateUrl: './priest-spell-card.component.html',
  styleUrls: ['./priest-spell-card.component.css']
})
export class PriestSpellCardComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() spellName: String;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  spellDetails( item: any) {
    this.router.navigate(['/priestSpells', item.id]);
  }

}
