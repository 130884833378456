import { Component, OnInit } from '@angular/core';
import { SpellService } from '../../providers/spell.service';


@Component({
  selector: 'app-priest-spells',
  templateUrl: './priest-spells.component.html',
  styleUrls: ['./priest-spells.component.css']
})
export class PriestSpellsComponent implements OnInit {

  allSpells: any[] = [];
  spells: any[] = [];
  levels: string[] = [];
  mensajeError: any = {};

  public spellName = '';

  spheres: string[] = [];

  filtros = {
    'levels': [],
    'spheres': []
   };

  constructor(private spellService: SpellService) {
    this.spellService.getAllPriestSpells()
    .subscribe( (data: any) => {
      this.allSpells = data;
    });
    this.spells = this.getSpells();
  }

  ngOnInit() {
  }

  addFilter(key, event: any) {
    if (key === 'name') {
      this.spellName = event.target.value;
    } else if (key === 'lvl') {
      if (this.levels.includes(event)) {
        const INDEX = this.levels.indexOf(event);
        if (INDEX > -1) {
          this.levels.splice(INDEX, 1);
        }
      } else {
        this.levels.push(event);
      }
    } else if (key === 'spheres') {
      if (this.spheres.includes(event)) {
        const INDEX = this.spheres.indexOf(event);
        if (INDEX > -1) {
          this.spheres.splice(INDEX, 1);
        }
      } else {
        this.spheres.push(event);
      }
    }

    this.spellerdos();
  }

  spellerdos() {
    let spellFiltered: any[] = [];
    let filterKeys: string[];

    if (this.spellName.length > 0) {
      filterKeys = ['name'];

      spellFiltered = this.allSpells.filter(item =>
        filterKeys.reduce((memo, keyName) =>
          (memo && new RegExp(this.spellName, 'gi').test(item[keyName])) || this.spellName === '', true));

      if (this.levels.length > 0) {
        filterKeys = ['lvl'];

        const spellFilteredLvl = spellFiltered.slice();
        spellFiltered.splice(0, spellFiltered.length);

        for (let i = 0; i < this.levels.length; i++) {
          const arrayTmp = spellFilteredLvl.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.levels[i], 'gi').test(item[keyName])) || this.levels[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }

      if (this.spheres.length > 0) {
        filterKeys = ['spheres'];

        const spellFilteredLvl = spellFiltered.slice();
        spellFiltered.splice(0, spellFiltered.length);

        for (let i = 0; i < this.spheres.length; i++) {
          const arrayTmp = spellFilteredLvl.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.spheres[i], 'gi').test(item[keyName])) || this.spheres[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }
    } else {
      if (this.levels.length > 0) {
        let spellFilteredLvl: string[];
        if (spellFiltered.length === 0) {
          spellFilteredLvl = this.allSpells.slice();
        } else {
          spellFilteredLvl = spellFiltered.slice();
          spellFiltered.splice(0, spellFiltered.length);
        }

        filterKeys = ['lvl'];

        for (let i = 0; i < this.levels.length; i++) {
          const arrayTmp = spellFilteredLvl.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.levels[i], 'gi').test(item[keyName])) || this.levels[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }

      if (this.spheres.length > 0) {
        let spellFilteredSpheres: string[];
        if (spellFiltered.length === 0) {
          spellFilteredSpheres = this.allSpells.slice();
        } else {
          spellFilteredSpheres = spellFiltered.slice();
          spellFiltered.splice(0, spellFiltered.length);
        }

        filterKeys = ['spheres'];

        for (let i = 0; i < this.spheres.length; i++) {
          const arrayTmp = spellFilteredSpheres.filter(item =>
            filterKeys.reduce((memo, keyName) =>
              (memo && new RegExp(this.spheres[i], 'gi').test(item[keyName])) || this.spheres[i] === '', true));

          arrayTmp.forEach(function (element) {
            spellFiltered.push(element);
          });
        }
      }
    }

    if (this.spellName.length === 0 && this.levels.length === 0 && this.spheres.length === 0) {
      this.spells = [];
    } else {
      this.spells = spellFiltered;
    }
  }

  getSpells() {
    const spellFiltered: any[] = [];

    if (this.filtros.levels.length > 0) {
      if (this.filtros.spheres.length > 0) {

        // filtrado por nivel y esfera
        for (const hechizo of this.allSpells) {
          for (const nivel of this.filtros.levels) {
            for (const sphere of this.filtros.spheres) {
              if (hechizo.lvl === nivel && hechizo.spheres.search(sphere) !== -1) {
                spellFiltered.push(hechizo);
              }
            }
          }
        }
      } else {

        // filtrado solo por nivel
        for (const hechizo of this.allSpells) {
          for (const nivel of this.filtros.levels) {
            if (hechizo.lvl === nivel) {
              spellFiltered.push(hechizo);
            }
          }
        }
      }
    } else if (this.filtros.spheres.length > 0) {

        // filtrado solo por esfera
        for (const hechizo of this.allSpells) {
          for (const sphere of this.filtros.spheres) {
            if (hechizo.spheres.search(sphere) !== -1) {
              spellFiltered.push(hechizo);
            }
          }
        }
    } else {
    }

    return spellFiltered;
  }

}
