import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SpellService} from '../../../providers/spell.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-priest-details',
  templateUrl: './priest-details.component.html',
  styleUrls: ['./priest-details.component.css']
})
export class PriestDetailsComponent implements OnInit {
  spell: any = {};
  body: any;

  constructor( private activatedRoute: ActivatedRoute, private spellService: SpellService, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe( params => {
      this.spellService.getMageSpell(params.id)
        .subscribe((data: any) => {
          this.spell = data;
        });
    });
  }

  ngOnInit() {
  }

}
