import {Component} from '@angular/core';
import {GeneratorService} from '../../../providers/generator.service';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-spell-book-sheet',
  templateUrl: './spell-book-sheet.component.html',
  styleUrls: ['./spell-book-sheet.component.css']
})
export class SpellBookSheetComponent {

  spells = [];

  items = [{title: 'first'}, {title: 'second'}]; // Content of the pages
  counter: number;
  length: number;

  constructor(private generator: GeneratorService) {
    this.generator.getSpells(6, 10, 7)
      .subscribe((data: any) => {
        this.spells = data;
      });
  }

  f1() {
    const imgWidth = 210;
    const pageHeight = 295;
    let position = 0;
    let heightLeft = pageHeight;
    const doc = new jsPDF('p', 'mm');
    const header = document.getElementById('header');

    const longitud = this.spells.length;

    html2canvas(header, {
      // Opciones
      allowTaint: true,
      useCORS: false,
      // Calidad del PDF
      scale: 1
    }).then(function (canvas) {
      const img = canvas.toDataURL('image/png');
      const imgHeight = canvas.height * imgWidth / canvas.width;
      if (heightLeft >= imgHeight + 5) {
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        position = imgHeight;
        heightLeft -= imgHeight;
      } else {
        heightLeft = pageHeight;
        doc.addPage();
        position = 0;
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        position += imgHeight;
        heightLeft -= imgHeight;
      }
    });

    for (let i = 0; i < longitud; i++) {
      const sheet = document.getElementById('sheet' + i);

      html2canvas(sheet, {
        // Opciones
        allowTaint: true,
        useCORS: false,
        // Calidad del PDF
        scale: 1
      }).then(function (canvas) {
        const img = canvas.toDataURL('image/png');
        const imgHeight = canvas.height * imgWidth / canvas.width;

        if (heightLeft >= imgHeight + 5) {
          doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
          position += imgHeight;
          heightLeft -= imgHeight;
        } else {
          heightLeft = pageHeight;
          doc.addPage();
          position = 0;
          doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
          position += imgHeight;
          heightLeft -= imgHeight;
        }

        if (i === longitud - 1) {
          doc.save('test.pdf');
        }
      });
    }
  }
}

