import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SpellService {

  constructor(private http: HttpClient) {
  }

  getQuery( query: string) {
    //const IP = window.location.hostname;
    //const URL = `http://${IP}:82/api/${query}`;
    const URL = `https://mgdnd.ddns.net/api/${query}`;

    return this.http.get(URL);
  }

  getAllMageSpells() {
    return this.getQuery('mageSpells')
    .pipe( map ( data => data ));
  }

  getAllPriestSpells() {
    return this.getQuery('priestSpells')
    .pipe( map ( data => data ));
  }

  getMageSpell(id: number) {
    return this.getQuery(`mageSpells/${id}`)
    .pipe( map ( data => data ));
  }

  getSpellByName( name: string) {
    return this.getQuery(`mageSpells/${name}`)
    .pipe( map ( data => data ));
  }
}
